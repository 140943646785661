import { UilImport } from '@iconscout/react-unicons';
import { UisExclamationCircle } from '@iconscout/react-unicons-solid';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { useId } from 'react';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const inputVariants = cva(
  `flex h-50 w-full rounded-8 bg-transparent px-16 py-5 paragraph-medium transition-colors placeholder:text-gray-200 focus-visible:outline-none focus-visible:border-orange-300 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-500 disabled:text-gray-50 file:border-0 file:label-small file:bg-orange-200 file:text-white file:rounded-12 file:px-16 file:h-40 file:-ml-10 file:mr-10`,
  {
    variants: {
      variant: {
        default: `text-gray-25 border border-gray-300`,
        destructive: `text-danger-red-100 border border-danger-red-100`,
      },
    },
    defaultVariants: {
      variant: `default`,
    },
  },
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => (
    <input
      type={type}
      className={cn(``, inputVariants({ variant, className }))}
      ref={ref}
      {...props}
    />
  ),
);

Input.displayName = `Input`;

export interface InputErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  error: string;
}

const InputError = React.forwardRef<HTMLDivElement, InputErrorProps>(
  ({ className, error, ...props }, ref) => (
    <div
      className={cn(
        `paragraph-xsmall flex items-center gap-4 text-danger-red-200`,
        className,
      )}
      {...props}
      ref={ref}
    >
      <UisExclamationCircle
        className="fill-danger-red-200"
        width="16"
        height="16"
      />
      {error}
    </div>
  ),
);

InputError.displayName = `InputError`;

const InputStartIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(`absolute left-6 top-1/2 -translate-y-1/2`, className)}
    {...props}
    ref={ref}
  />
));

InputStartIcon.displayName = `InputStartIcon`;

const InputEndIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(`absolute right-6 top-1/2 -translate-y-1/2`, className)}
    {...props}
    ref={ref}
  />
));

InputEndIcon.displayName = `InputStartIcon`;

const changeFileInputVariants = cva(`ofverflow-hidden`, {
  variants: {
    direction: {
      vertical: `flex flex-col gap-24`,
      horizontal: `flex items-center gap-32 items-center`,
    },
  },
  defaultVariants: {
    direction: `horizontal`,
  },
});

export interface ChangeFileInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof changeFileInputVariants> {
  filename: string;
  fileUrl: string;
}

const ChangeFileInput = React.forwardRef<
  HTMLInputElement,
  ChangeFileInputProps
>(({ direction, filename, fileUrl, ...props }, ref) => {
  const inputId = useId();

  return (
    <div className={cn(``, changeFileInputVariants({ direction }))}>
      <div className="flex items-center gap-16 overflow-hidden">
        <span className="paragraph-medium truncate">{filename}</span>
        <a download className="contents" target="_blank" href={fileUrl}>
          <button className="flex size-36 shrink-0 items-center justify-center rounded-full bg-gray-500">
            <UilImport className="size-20 fill-gray-25" />
          </button>
        </a>
      </div>

      <label htmlFor={inputId} className="shrink-0">
        <Button
          variant="secondary"
          size="lg"
          className="h-50 w-full min-w-144 shrink-0"
          asChild
        >
          <span>Change File</span>
        </Button>
      </label>
      <Input
        className="hidden"
        id={inputId}
        name={inputId}
        type="file"
        {...props}
        ref={ref}
      />
    </div>
  );
});

ChangeFileInput.displayName = `ChangeFileInput`;

export {
  Input,
  InputError,
  InputStartIcon,
  InputEndIcon,
  ChangeFileInput,
  inputVariants,
  changeFileInputVariants,
};
