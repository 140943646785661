import { ReactNode } from 'react';

import AppBar from '@/components/Layout/AppBar';
import Background from '@/components/Layout/Background';
import Container from '@/components/Layout/Container';
import MobilePx16 from '@/components/Layout/MobilePx16';
import Paper from '@/components/Layout/Paper';
import PhoneNumberForm from '@/components/Molecule/PhoneNumberForm';
import useFetching from '@/hooks/useFetching';
import useMyArtistInvitationQuery from '@/hooks/useMyArtistInvitationQuery';

function Wrapper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <Background backgroundColor="#FFFFFF">
      <AppBar start={<AppBar.MenuButton />} backgroundColor="#FFFFFF" />

      <div className="h-88 md:h-78" />
      <Container className="md:max-w-480">
        <MobilePx16>
          <Paper>{children}</Paper>
        </MobilePx16>
      </Container>
    </Background>
  );
}

function PhoneNumber() {
  const {
    data: artistInvitation,
    isLoading,
    error,
  } = useMyArtistInvitationQuery();

  useFetching({ data: artistInvitation, isLoading, error });

  if (isLoading) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <PhoneNumberForm prefilledPhoneNumber={artistInvitation?.phonenumber} />
    </Wrapper>
  );
}

export default PhoneNumber;
