import Image, { ImageProps } from 'next/legacy/image';
import { ErrorBoundary } from 'react-error-boundary';

import fallbackImage from '@/assets/music.svg';

type ImageWithFallbackProps = ImageProps & {
  key: string | number;
};

function ImageWithFallback(props: ImageWithFallbackProps) {
  const { src, alt, blurDataURL, ...others } = props;

  return (
    <ErrorBoundary
      /* eslint-disable-next-line react/no-unstable-nested-components */
      fallbackRender={() => (
        <Image
          {...others}
          blurDataURL={blurDataURL || fallbackImage}
          alt={alt}
          src={src || fallbackImage}
          unoptimized
          onErrorCapture={() => {
            // eslint-disable-next-line no-console
            console.error(`error while loading ${src}`);
          }}
        />
      )}
      onError={(error) => {
        // eslint-disable-next-line no-console
        console.error(`error while loading ${src}`);
      }}
    >
      <Image
        {...others}
        blurDataURL={blurDataURL || fallbackImage}
        alt={alt}
        src={src || fallbackImage}
        unoptimized={false}
      />
    </ErrorBoundary>
  );
}

export default ImageWithFallback;
