import { captureException } from '@sentry/core';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/components/ui/button';
import { Input, InputEndIcon } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PhoneInput } from '@/components/ui/phone-input';
import {
  useSendVerificationCodeMutation,
  useVerifyCodeMutation,
} from '@/generated/graphql';
import { useAuth } from '@/hooks/useAuth';
import { catchDemoUserError } from '@/utils/demo';
import { useLoading } from '@/utils/Loading/LoadingProvider';

function PhoneNumberForm(props: {
  prefilledPhoneNumber?: string;
  onComplete?: {
    (_: unknown): unknown;
  };
}) {
  const { prefilledPhoneNumber, onComplete } = props;

  const { refetch } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>(
    prefilledPhoneNumber ?? ``,
  );
  const [verificationCode, setVerificationCode] = useState(``);
  const [sent, setSent] = useState(false);

  const { loader } = useLoading();

  const formattedPhoneNumber = useMemo(
    () =>
      phoneNumber.length > 0 && phoneNumber[0] === `+`
        ? phoneNumber
        : `+${phoneNumber}`,
    [phoneNumber],
  );

  const [sendVerificationCodeMutation] = useSendVerificationCodeMutation();

  const sendVerificationCode = useCallback(async () => {
    loader.on();

    try {
      await sendVerificationCodeMutation({
        variables: {
          phonenumber: formattedPhoneNumber,
        },
      }).catch(catchDemoUserError);
      setSent(true);
      toast.success(`Sent successfully`);
    } catch (e) {
      setSent(false);
      toast.error(`${e}`);
      captureException(e);
    }
    loader.off();
  }, [formattedPhoneNumber, loader, sendVerificationCodeMutation]);

  const [verifyCodeMutation] = useVerifyCodeMutation();
  const verifyCode = useCallback(async () => {
    loader.on();

    try {
      await verifyCodeMutation({
        variables: {
          phonenumber: formattedPhoneNumber,
          code: verificationCode,
        },
      }).catch(catchDemoUserError);
      toast.success(`Verified successfully`);
      await refetch();
      if (onComplete) {
        onComplete(undefined);
      }
    } catch (e) {
      toast.error(`${e}`);
      captureException(e);
    }
    loader.off();
  }, [
    formattedPhoneNumber,
    loader,
    onComplete,
    refetch,
    verificationCode,
    verifyCodeMutation,
  ]);

  const readyToSubmit = phoneNumber.length > 0 && verificationCode.length > 0;

  return (
    <div className="flex grow flex-col">
      <div className="header-1-b text-gray-10">Enter your phone number</div>
      <div className="h-16" />
      <div className="body-2-r text-gray-100">
        We use your phone number to send you gig recommendations via text.
      </div>
      <div className="h-40" />
      <div className="grid gap-8">
        <Label htmlFor="phone-number">Phone number</Label>
        <div className="relative w-full">
          <PhoneInput
            value={phoneNumber}
            onChange={setPhoneNumber}
            inputProps={{
              id: `phone-number`,
            }}
            placeholder="+1 (555) 555-5555"
          />
          <InputEndIcon>
            <Button
              variant="primary"
              className="h-38 font-medium"
              onClick={sendVerificationCode}
            >
              {sent ? `Resend` : `Send code`}
            </Button>
          </InputEndIcon>
        </div>
      </div>
      {sent && (
        <>
          <div className="h-32" />
          <div className="grid gap-8">
            <Label htmlFor="verification-code">Verification code</Label>
            <Input
              id="verification-code"
              name="verification-code"
              value={verificationCode}
              onChange={(e) => {
                e.preventDefault();
                setVerificationCode(e.target.value);
              }}
              type="tel"
              placeholder="Input code"
            />
          </div>
        </>
      )}

      <div className="h-20 max-md:grow md:h-48" />
      <Button
        size="lg"
        variant="primary"
        onClick={verifyCode}
        disabled={!readyToSubmit}
      >
        Next
      </Button>
    </div>
  );
}

export default PhoneNumberForm;
