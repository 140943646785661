import useSWR from 'swr';

import {
  MyArtistInvitationDocument,
  MyArtistInvitationQuery,
  MyArtistInvitationQueryVariables,
} from '@/generated/graphql';
import client from '@/graphql/apollo';

function useMyArtistInvitationQuery() {
  return useSWR([`/invitation`], async () =>
    client
      .query<MyArtistInvitationQuery, MyArtistInvitationQueryVariables>({
        query: MyArtistInvitationDocument,
      })
      .then((it) => it.data.me?.artistInvitation ?? null),
  );
}

export default useMyArtistInvitationQuery;
