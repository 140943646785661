import constants from '@/constants/constants';

export function catchDemoUserError(error: unknown, value?: unknown) {
  if (
    error instanceof Error &&
    error.message === constants.DEMO_USER_ERROR_MESSAGE
  ) {
    return value;
  }

  throw error;
}
