class InvalidState extends Error {
  constructor(msg?: string) {
    super(msg ?? `Invalid State`);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvalidState.prototype);
  }
}

export default InvalidState;
