'use client';

import { UilCheck } from '@iconscout/react-unicons';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';

import { cn } from '@/lib/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      `peer h-24 w-24 shrink-0 rounded-sm border border-gray-300 text-white transition-all hover:border-orange-300 focus-visible:border-orange-300 focus-visible:outline-none disabled:cursor-not-allowed disabled:border disabled:border-solid disabled:border-gray-300 disabled:bg-gray-500 disabled:text-gray-200 data-[state=checked]:border-0 data-[state=checked]:bg-orange-200 hover:data-[state=checked]:bg-orange-300`,

      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(`flex items-center justify-center text-current`)}
    >
      <UilCheck className="size-24" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
