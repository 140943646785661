import React from 'react';
import PhoneInput2, {
  PhoneInputProps as PhoneInput2Props,
} from 'react-phone-input-2';

import { inputVariants } from '@/components/ui/input';
import { cn } from '@/lib/utils';

import type { VariantProps } from 'class-variance-authority';

export interface PhoneInputProps
  extends PhoneInput2Props,
    VariantProps<typeof inputVariants> {}

function PhoneInput({
  dropdownClass,
  inputClass,
  variant,
  ...props
}: PhoneInputProps) {
  return (
    <PhoneInput2
      country="us"
      dropdownClass={cn(
        `relative body-2-r border border-solid border-gray-300`,
        dropdownClass,
      )}
      inputClass={cn(
        inputVariants({
          variant,
          className: inputClass,
        }),
      )}
      {...props}
    />
  );
}

export { PhoneInput };
