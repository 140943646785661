import React, { ReactNode } from 'react';

export function PageHeader({
  children,
  icon,
}: {
  children?: ReactNode;
  icon: ReactNode;
}) {
  return (
    <div className="flex items-center gap-16">
      <div className="flex-center size-36 shrink-0 rounded-8 bg-gray-25">
        <div className="size-24 text-white">{icon}</div>
      </div>
      <span className="heading-xlarge text-gray-25">{children}</span>
    </div>
  );
}
