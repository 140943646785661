'use client';

import { UilSignin } from '@iconscout/react-unicons';
import { FormEvent, MouseEvent, ReactNode, useCallback, useState } from 'react';

import AppBar from '@/components/Layout/AppBar';
import Background from '@/components/Layout/Background';
import Container from '@/components/Layout/Container';
import MobilePx16 from '@/components/Layout/MobilePx16';
import Paper from '@/components/Layout/Paper';
import { PageHeader } from '@/components/Molecule/PageHeader';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/hooks/useAuth';
import { InternalLink } from '@/hooks/useInternalRouter';

import MdOnly from 'src/components/Layout/MdOnly';

function Wrapper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <Background backgroundColor="#FFFFFF">
      <AppBar
        start={<AppBar.LogoButton />}
        end={<AppBar.MenuButton />}
        backgroundColor="#FFFFFF"
      />
      <div className="h-40 md:h-78" />

      <Container className="md:max-w-480">
        <MdOnly>
          <PageHeader icon={<UilSignin className="size-24" />}>
            Log in
          </PageHeader>
          <div className="h-40" />
        </MdOnly>
        <MobilePx16>
          <Paper>{children}</Paper>
        </MobilePx16>
      </Container>
    </Background>
  );
}

function Signin() {
  const { login } = useAuth();

  const [username, setUsername] = useState(``);
  const [password, setPassword] = useState(``);
  const handleSubmit = useCallback(
    async (event: MouseEvent | FormEvent) => {
      event.preventDefault();

      login({ username, password });
    },
    [login, password, username],
  );
  const readyToSubmit = username.length > 0 && password.length > 0;

  return (
    <Wrapper>
      <form className="flex grow flex-col" onSubmit={handleSubmit}>
        <Input
          value={username}
          onChange={(e) => {
            e.preventDefault();
            setUsername(e.target.value);
          }}
          placeholder="Username or email"
        />
        <div className="h-16" />
        <Input
          value={password}
          onChange={(e) => {
            e.preventDefault();
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Password"
        />
        <div className="h-24" />
        <div className="flex w-full justify-end">
          <InternalLink href="/account/password/reset" className="contents">
            <span className="body-2-r text-right text-gray-100">
              Forgot password?
            </span>
          </InternalLink>
        </div>
        <div className="h-32 md:h-48" />
        <Button
          size="lg"
          variant="primary"
          onClick={handleSubmit}
          disabled={!readyToSubmit}
        >
          Log in
        </Button>
        <div className="h-48" />

        <InternalLink href="/signup" className="contents">
          <div className="flex-center gap-8">
            <span className="body-2-r text-gray-100">New to GigFinesse?</span>
            <span className="header-5-m text-gray-25">Sign up</span>
          </div>
        </InternalLink>
      </form>
      <div className="flex flex-col gap-40" />
    </Wrapper>
  );
}

export default Signin;
