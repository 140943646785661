import { captureException } from '@sentry/core';
import Link from 'next/link';
import { ReactNode, useCallback, useId, useState } from 'react';
import toast from 'react-hot-toast';

import AppBar from '@/components/Layout/AppBar';
import Background from '@/components/Layout/Background';
import Container from '@/components/Layout/Container';
import MobilePx16 from '@/components/Layout/MobilePx16';
import Paper from '@/components/Layout/Paper';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { useAgreeNewTermsMutation } from '@/generated/graphql';
import { useAuth } from '@/hooks/useAuth';
import { catchDemoUserError } from '@/utils/demo';
import { useLoading } from '@/utils/Loading/LoadingProvider';

function Wrapper(props: { children?: ReactNode }) {
  const { children } = props;

  return (
    <Background backgroundColor="#FFFFFF">
      <AppBar start={<AppBar.MenuButton />} backgroundColor="#FFFFFF" />
      <div className="h-40 md:h-78" />

      <Container className="md:max-w-480">
        <MobilePx16>
          <Paper>{children}</Paper>
        </MobilePx16>
      </Container>
    </Background>
  );
}

function AgreeNewTerms() {
  const { refetch } = useAuth();
  const { loader } = useLoading();
  const checkboxId = useId();
  const [tosAgree, setTosAgree] = useState(false);
  const [agreeNewTerms] = useAgreeNewTermsMutation();
  const acceptNewTerms = useCallback(async () => {
    loader.on();

    try {
      await agreeNewTerms().catch(catchDemoUserError);
      toast.success(`Submitted successfully`);
      await refetch();
    } catch (e) {
      toast.error(`${e}`);
      captureException(e);
    }
    loader.off();
  }, [agreeNewTerms, loader, refetch]);

  return (
    <Wrapper>
      <div className="flex grow flex-col">
        <div className="header-1-b text-gray-10">
          Updated terms for your account
        </div>
        <div className="h-16" />
        <div className="body-2-r text-gray-100">
          We have updated the Terms of Service and the Privacy Policy. Thank you
          for booking through GigFinesse!
        </div>
        <div className="h-40" />
        <div className="flex items-start gap-10">
          <Checkbox
            checked={tosAgree}
            onCheckedChange={(state) =>
              typeof state === `boolean` && setTosAgree(state)
            }
            id="setTosAgree"
          />
          <Label htmlFor="setTosAgree" className="h-auto">
            I agree to the
            <Link href="https://www.gigfinesse.com/about/terms">
              <span className="text-orange-200">{` Terms of Service `}</span>
            </Link>
            and the
            <Link href="https://www.gigfinesse.com/about/privacy">
              <span className="text-orange-200">{` Privacy Policy`}</span>
            </Link>
          </Label>
        </div>
        <div className="h-20 max-md:grow md:h-48" />
        <Button
          size="lg"
          variant="primary"
          onClick={acceptNewTerms}
          disabled={!tosAgree}
        >
          Accept New Terms
        </Button>
      </div>
    </Wrapper>
  );
}

export default AgreeNewTerms;
